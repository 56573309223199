<template>
  <v-sheet class="home" style="height: 100vh">
    <div class="login d-flex align-center justify-center" style="height: 100%">
      <v-card max-width="600" class="pa-5 mx-3">
        <v-card-text class="d-flex flex-column align-center justify-center">
          <v-icon x-large color="success">mdi-check-decagram</v-icon>
          <h1 class="mt-10">Order created successfully!</h1>
          <span class="mt-5">OrderID: {{ orderId }}</span>
        </v-card-text>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>

import * as fb from '../../firebase'

export default {

  data() {
    return {
      orderId: 'SN-CMP-00001'
    }
  },

  methods: {
    async updateDocId() {

      const order = await fb.db.doc(`order/${this.$route.params.id}`).get()

      this.orderId = order.data().customId



      const updateDocId = await fb.db.doc(`order/${this.$route.params.id}`).update({
        docId: this.$route.params.id,
      });
      console.log(updateDocId)
    }
  },

  created() {
    this.updateDocId()
  }

}
</script>

<style>

</style>